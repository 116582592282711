$body-bg: #FFFDF8;
$border-radius: 0;
$border-radius-lg: 0;
$link-color: black;
$body-tertiary-bg: $body-bg;
$card-border-width: 0;
$card-spacer-y: 0;
$font-family-sans-serif: Space Grotesk, sans-serif;
$font-family-monospace: Space Mono, monospace;
$navbar-padding-y: 0;
$accordion-body-padding-x: 29px;
$accordion-body-padding-y: 14px;
$accordion-button-padding-x: 29px;
$accordion-button-padding-y: 19px;

@import '~bootstrap/scss/bootstrap.scss';


// Layout

.profession-card {
  padding: 0;
}

.profession-row {
  margin-left: 0;
  margin-right: 0;
}

.faqs-title {
  margin-bottom: 36px;
}

.faqs-wrapper {
  padding-left: 22px;
  padding-right: 22px;
  padding-top: 42px;
  padding-bottom: 53px;
}

.container {
  padding-left: 46px;
  padding-right: 46px;
}

.nav-container,
.header-container {
  padding-top: 26px;
  padding-bottom: 26px;
}

.form-container {
  padding-top: 48px;
  padding-bottom: 48px;

  @include media-breakpoint-up(lg) {
    padding-bottom: 0px;
  }  
}

.form-col {
  padding-bottom: 48px;

  @include media-breakpoint-up(lg) {
    margin-top: -60px;
  } 

  @include media-breakpoint-up(xl) {
    margin-top: -90px;
  } 
}

.results-card,
.form-col {
  @include media-breakpoint-up(lg) {
    padding-top: 50px;
    padding-left: 30px;
    padding-right: 30px;
  } 
}

.results-card {
  @include media-breakpoint-up(lg) {
    margin-top: -30px;
  } 

  @include media-breakpoint-up(xl) {
    margin-top: -50px;
  } 
}

.results-container {
  padding-top: 48px;
  padding-bottom: 32px;
}

.professions-container {
  padding: 0px;
}

.faqs-container {
  padding: 34px 18px;
}

.header-image {
  width: 100%;
}

.footer-container {
  padding: 27px 25px 38px;
}

@include media-breakpoint-down(md) {
  .card-body {
    padding-left: 100px;
    padding-right: 100px;
  }
}

.results-graph {
  margin-bottom: 16px;
}

.input-group {
  margin-top: 14px;
  margin-bottom: 24px;
}

.profession-card-heading p {
  padding-top: 14px;
  padding-bottom: 14px;
}

// Typography

@mixin medium-font-size {
  font-size: 24px;

  @include media-breakpoint-up(md) {
    font-size: 36px;
  }  

  @include media-breakpoint-up(md) {
    font-size: 46px;
  }  
}

@mixin small-font-size {
  font-size: 14px;

  @include media-breakpoint-up(md) {
    font-size: 16px;
  }

  @include media-breakpoint-up(lg) {
    font-size: 20px;
  }  
}

.navbar-brand {
  font-family: $font-family-monospace;
  font-weight: 400;
  font-size: 18px;

  @include media-breakpoint-up(md) {
    font-size: 24px;
  }

  @include media-breakpoint-up(lg) {
    font-size: 30px;
  }

  line-height: 1.5;
}

.nav-link {
  font-family: $font-family-monospace;
  @include small-font-size;
  font-weight: 400;
  letter-spacing: 0.1em;

  color: black;

  padding: 4px;
}

h1 {
  font-family: $font-family-monospace;
  @include medium-font-size;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: 0.05em;
}

p {
  font-size: 16px;
  font-weight: 400;
  line-height: 2.125;

  @include media-breakpoint-up(md) {
    font-size: 20px;
    line-height: 1.7;
  }
}

.profession-card-heading p {
  font-family: Space Mono, monospace;
  font-size: 24px;
  font-weight: 700;
  line-height: 1.5;
}

.profession-card-percentage {
  font-size: 40px;
  font-weight: 700;
  line-height: 1.275;

  @include media-breakpoint-up(lg) {
    font-size: 50px;
  }
}

.profession-card-contents {
  font-size: 16px;
  font-weight: 700;
  line-height: 1.25;

  @include media-breakpoint-up(lg) {
    font-size: 20px;
  }
}

.faqs-title {
  font-family: Space Mono, monospace;
  @include medium-font-size;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: 0.05em;
}

.accordion-button {
  background: linear-gradient(0deg, #F3F2F2, #F3F2F2),
    linear-gradient(0deg, #000000, #000000);
  border-bottom: 2px solid #000000;

  font-size: 16px;
  font-weight: 700;
  line-height: 1.25;

  @include media-breakpoint-up(lg) {
    font-size: 24px;
  }
}

.accordion-button:not(.collapsed) {
  color: black;
}

.accordion-body p {
  @include small-font-size;
  font-weight: 400;
  line-height: 1.43;
}

footer p {
  font-size: 10px;
  font-weight: 400;
  line-height: 1.3;

  @include media-breakpoint-up(md) {
    font-size: 12px;
  }
}

.form-floating>label,
.form-select,
.input-group-text {
    font-size: 18px;
    font-weight: 400;
}

.form-floating>label>.font-weight-bold {
  font-size: 18px;
  font-weight: 700;
}

.btn.btn-calculate,
.btn.btn-calculate:hover,
.btn.btn-calculate:focus,
.btn.btn-calculate:active,
/* for precedence over an existing style */
:not(.btn-check)+.btn.btn-calculate:active {
    font-size: 18px;
    font-weight: 400;

    @include media-breakpoint-up(md) {
      font-size: 24px;
    }
}


// Colours

.nav-container {
  box-shadow: 0px 2px 0px rgba(240, 126, 61, 0.5);
  border-bottom: 1px solid black;
}

.container {
  @include media-breakpoint-down(lg) {
    border-bottom: 1px solid black;
  }
}

.nav-link {
  border: #f89055 1px solid;

  @include media-breakpoint-up(md) {
    border: none;
    border-bottom: #f89055 4px solid;
  }
}

.profession-card-heading {
  background: #D9D9D9;
}

.profession-card:nth-child(2n-1) > .card-body {
  background: #E4E4E4;
}

.profession-card:nth-child(2n) > .card-body {
  background: #F3F2F2;
}

.faqs-wrapper {
  border: 1px solid #000000;
  box-shadow: 4px 4px 0px 0px #00000040;
}

.accordion-button {
  background: linear-gradient(0deg, #F3F2F2, #F3F2F2),
    linear-gradient(0deg, #000000, #000000);
  border-bottom: 2px solid #000000;
}

.accordion-item {
  border: none;
}

.footer-container {
  background: #F3F2F2;
  border-bottom: none;
  border-top: 1px solid #000;
}

.input-group {
    border: 1px solid #000;
    box-shadow: 2px 2px 1px 0px rgba(0, 0, 0, 0.25) inset;
}

.btn.btn-calculate,
.btn.btn-calculate:hover,
.btn.btn-calculate:focus,
.btn.btn-calculate:active,
/* for precedence over an existing style */
:not(.btn-check)+.btn.btn-calculate:active {
    border-radius: 18px;
    padding: 6px 21px 6px 21px;
    background: #F89055;
    border: none;

    @include media-breakpoint-up(md) {
      padding: 2px 23px 2px 23px;
    }
}

.results-card,
.form-col {
  @include media-breakpoint-up(lg) {
    border: 1px solid #000;
    border-bottom: none;
    box-shadow: 4px 0px 0px 0px #00000040;
  }
}

// Appearance

/* Works for Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Works for Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}
